import React from "react";
import styled from "styled-components";
import BaseProductCard from "./BaseProductCard";
import { GetBaseProductsQuery } from "../../generated/graphql";

type BaseProducts = GetBaseProductsQuery['baseProducts'];
// TODO: eventually tackle type duplication

type ComponentProps = {
  baseProducts: BaseProducts
}

const BaseProductsDisplayContainer = styled.div`
  width: 90%;
`;

const ProductList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Creates three columns of equal width */
  gap: 20px; /* Space between items */
`;

const BaseProductsDisplay: React.FC<ComponentProps> = ({ baseProducts }) => {
  return (
    <BaseProductsDisplayContainer>
      <ProductList>
        {
          baseProducts.map((product, index) => <BaseProductCard baseProduct={product} key={index}/>)
        }
      </ProductList>
    </BaseProductsDisplayContainer>
  );
};

export default BaseProductsDisplay;