import React from "react";
import styled from "styled-components";
import MainContentProduct from "./MainContentProduct";
import MainContentPhoto from "./MainContentPhoto";
import { GetBaseProductQuery } from "../../../generated/graphql";

type ComponentProps = {
  baseProduct: GetBaseProductQuery['baseProduct'];
}

const MainContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-between;
`;

const MainContentArea: React.FC<ComponentProps> = ({ baseProduct }) => {
  return (
    <MainContentContainer>
      <MainContentPhoto baseProduct={baseProduct} />
      <MainContentProduct baseProduct={baseProduct} />
    </MainContentContainer>
  );
};

export default MainContentArea;