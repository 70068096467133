import React from "react";
import BaseProductsDisplay from "../components/baseProducts/BaseProductsDisplay";
import { useSearchParams } from "react-router-dom";
import { useGetBaseProductsQuery } from "../generated/graphql";

const BaseProductsPage = () => {
  const [queryParams] = useSearchParams();
  const searchText = queryParams.get('s');

  const { data } = useGetBaseProductsQuery({
    variables: { search: searchText },
  });

  return (
    <>
      {
        data ? <BaseProductsDisplay baseProducts={data.baseProducts} /> : null
      }
    </>
  );
};

export default BaseProductsPage;