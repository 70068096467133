import React from "react";
import VerticalTable from "../../common/tables/VerticalTable";
import styled from "styled-components";
import { GetBaseProductQuery } from "../../../generated/graphql";

type ComponentProps = {
  baseProduct: GetBaseProductQuery['baseProduct'];
}

const SummaryQuantityTierPricingsContainer = styled.div`
  width: 90%;
`;

const SummaryQuantityTierPricings: React.FC<ComponentProps> = ({ baseProduct }) => {
  const headers = [
    "Order Quantity",
    `Price ${baseProduct.saleUnit}`
  ];

  const rows = baseProduct.quantityTierPricings.map((quantityTierPricing) => {
    const pricingRange = quantityTierPricing.maxRangeQuantity ?
      `${quantityTierPricing.minRangeQuantity} - ${quantityTierPricing.maxRangeQuantity}` :
      `${quantityTierPricing.minRangeQuantity}+`;
    const perUnitCost = `$${quantityTierPricing.priceAmount}`;
    return [pricingRange, perUnitCost];
  });

  return (
    <SummaryQuantityTierPricingsContainer>
      <VerticalTable headers={headers} rows={rows} />
    </SummaryQuantityTierPricingsContainer>
  );
};

export default SummaryQuantityTierPricings;