import React from "react";
import styled from "styled-components";
import DetailDropdown from "./DetailDropdown";
import { Link } from "react-router-dom";
import CTAButton from "../../common/buttons/CTAButton";
import { GetBaseProductQuery } from "../../../generated/graphql";

type ComponentProps = {
  baseProduct: GetBaseProductQuery['baseProduct'];
}

// TODO: add custom resolvers for min price, max price, etc

const BaseProductPrice: React.FC<ComponentProps> = ({ baseProduct }) => {
  const pricings = baseProduct.quantityTierPricings.map(({ priceAmount }) => priceAmount).sort().reverse();
  const minPrice = pricings[0];
  const maxPrice = pricings[pricings.length - 1];
  const productPriceRange = (minPrice && maxPrice) ? `$${minPrice} - $${maxPrice}` : "TBD";

  // TODO: need to add pluralization
  // TODO: check on sale unit display
  return (
    <div>
      <span>{`${productPriceRange} ${baseProduct.saleUnit}`}</span>
    </div>
  );
};

const CollaborationCtaButton = styled(CTAButton)`
  width: 90%;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const BaseProductDescription = styled.p`
  text-align: left;
  width: 90%;
`;

const MainContentProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55%;
`;

const MainContentProduct: React.FC<ComponentProps> = ({ baseProduct }) => {
  return (
    <MainContentProductContainer>
      <h2>{baseProduct.companyName}</h2>
      <h1>{baseProduct.productName}</h1>
      <BaseProductPrice baseProduct={baseProduct} />
      <Link to={`/base_products/${baseProduct.id}/order`}>
        <CollaborationCtaButton>Request to Collaborate</CollaborationCtaButton>
      </Link>
      <h3>Description</h3>
      <BaseProductDescription>{baseProduct.description}</BaseProductDescription>
      <DetailDropdown name={"Sample"} content={baseProduct.sample} />
      <DetailDropdown name={"MOQ"} content={baseProduct.minimumOrderQuantity} />
      <DetailDropdown name={"Shipping"} content={baseProduct.shipping} />
      <DetailDropdown name={"Lead Time"} content={baseProduct.leadTime} />
    </MainContentProductContainer>
  );
};

export default MainContentProduct;