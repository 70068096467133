import React from "react";
import styled from "styled-components";
import { GetBaseProductQuery } from "../../../generated/graphql";
import Customization from "./Customization";

type ComponentProps = {
  baseProduct: GetBaseProductQuery["baseProduct"];
}

const CustomizationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  text-align: left;
`;


const Customizations: React.FC<ComponentProps> = ({ baseProduct }) => {
  return (
    <CustomizationsContainer>
      <h3>Customizations</h3>
      {
        baseProduct.customizations.map((customization) => {
          return <Customization
            key={customization.id}
            customization={customization}
          />;
        })
      }
    </CustomizationsContainer>
  );
};

export default Customizations;