import React from "react";
import styled from "styled-components";
import Pricing from "./pricing/Pricing";
import Customizations from "./customizations/Customizations";
import MainContentArea from "./mainContent/MainContentArea";
import { GetBaseProductQuery } from "../../generated/graphql";

type ComponentProps = {
  baseProduct: GetBaseProductQuery['baseProduct'];
}

const BaseProductDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BaseProductDisplay: React.FC<ComponentProps> = ({ baseProduct }) => {
  return (
    <BaseProductDisplayContainer>
      <MainContentArea baseProduct={baseProduct} />
      <Pricing baseProduct={baseProduct} />
      <Customizations baseProduct={baseProduct} />
    </BaseProductDisplayContainer>
  );
};

export default BaseProductDisplay;