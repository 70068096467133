import React from "react";
import styled from "styled-components";

type ComponentProps = {
  imageURL: string | null | undefined
  description: string
}

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const ImageContainer: React.FC<ComponentProps> = ({ imageURL, description }) => {
  const src = imageURL || undefined;

  return (
    <Image src={src} alt={description} />
  );
};

export default ImageContainer;