import React from "react";
import { useForm, SubmitHandler, FieldErrors } from "react-hook-form";
import { toast } from 'react-toastify';
import styled from "styled-components";
import TextInput from "../../common/inputs/TextInput";
import CTAInput from "../../common/inputs/CTAInput";
import BackButton from "../../common/buttons/BackButton";
import TextAreaInput from "../../common/inputs/TextAreaInput";
import { BaseProductOrderInputs } from "../../../types/shared";
import Customizations from "./Customizations";
import { GetBaseProductQuery, useCreateBaseProductOrderMutation } from "../../../generated/graphql";

type ComponentProps = {
  baseProduct: GetBaseProductQuery['baseProduct'];
}

const BaseProductOrderFormContainer = styled.div`
  width: 30%;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const YourDetailsGrid = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 1em;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-template-areas: 
    "fullName fullName"
    "email phone"
`;

const FullNameContainer = styled.div`
  grid-area: fullName;
`;

const EmailContainer = styled.div`
  grid-area: email;
`;

const PhoneContainer = styled.div`
  grid-area: phone;
`;

const CompanyDetailsGrid = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 1em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 
    "companyName companyWebsite"
    "shippingAddress shippingAddress"
`;

const CompanyNameContainer = styled.div`
  grid-area: companyName;
`;

const CompanyWebsiteContainer = styled.div`
  grid-area: companyWebsite;
`;

const ShippingAddressContainer = styled.div`
  grid-area: shippingAddress;
`;

const OrderDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubmitButton = styled(CTAInput)`
  margin: 2em 0;
`;

const BaseProductOrderForm: React.FC<ComponentProps> = ({ baseProduct }) => {
  const {
    register,
    handleSubmit,
  } = useForm<BaseProductOrderInputs>();

  const [createBaseProductOrder] = useCreateBaseProductOrderMutation();


  const onSubmit: SubmitHandler<BaseProductOrderInputs> = async (data) => {
    createBaseProductOrder({
      variables: {
        fullName: data.fullName,
        email: data.email,
        phone: data.phone,
        companyName: data.companyName,
        companyWebsite: data.companyWebsite,
        shippingAddress: data.shippingAddress,
        quantity: data.quantity,
        baseProductId: baseProduct.id
      }
    }).then(() => {
      toast.success("Your order was created");
    }).catch(() => {
      toast.error("Sorry, we ran into an error creating your order.");
    });
  };

  const onError = (errors: FieldErrors<BaseProductOrderInputs>) => {
    Object.values(errors).forEach(error => {
      toast.error(error.message?.toString());
    });
  };

  return (
    <BaseProductOrderFormContainer>
      <BackButton destination={`/base_products/${baseProduct.id}`} />
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <h1>Request to collaborate with {baseProduct.companyName}</h1>
        <h2>Your Details</h2>
        <YourDetailsGrid>
          <FullNameContainer>
            <TextInput placeholder="Full Name" {...register("fullName", { required: 'Full Name is required' })} />
          </FullNameContainer>
          <EmailContainer>
            <TextInput placeholder="Email" {...register("email", { required: 'Email is required' })} />
          </EmailContainer>
          <PhoneContainer>
            <TextInput placeholder="Phone" {...register("phone", { required: 'Phone is required' })} />
          </PhoneContainer>
        </YourDetailsGrid>
        
        <h2>Company Details</h2>
        <CompanyDetailsGrid>
          <CompanyNameContainer>
            <TextInput placeholder="Company Name" {...register("companyName", { required: 'Company Name is required' })} />
          </CompanyNameContainer>
          <CompanyWebsiteContainer>
            <TextInput placeholder="Company Website" {...register("companyWebsite", { required: 'Company Website is required' })} />
          </CompanyWebsiteContainer>
          <ShippingAddressContainer>
            <TextInput placeholder="Shipping Address" {...register("shippingAddress", { required: 'Shipping Address is required' })} />
          </ShippingAddressContainer>
        </CompanyDetailsGrid>

        <h2>Order Details</h2>
        <OrderDetailsContainer>
          <TextInput placeholder="Quantity" {...register("quantity", { required: 'Quantity is required' })} margin="1em 0" />
          <Customizations baseProduct={baseProduct} register={register} />
          <TextAreaInput placeholder="Additional Notes" />
        </OrderDetailsContainer>
        <SubmitButton type="submit" />
      </Form>
    </BaseProductOrderFormContainer>
  );
};

export default BaseProductOrderForm;