import React from "react";
import styled from "styled-components";
import SectionHeader from "../../common/display/SectionHeader";
import TextLine from "../../common/display/TextLine";
import TextContentContainer from "../shared/TextContentContainer";
import Image from "../../common/display/Image";

const ImageContent = () => {
  return (
    <Image imageURL="aboutUs/sellers.png" description="manufacturers" />
  );
};

const TextContent = () => {
  return (
    <TextContentContainer>
      <SectionHeader>Brands</SectionHeader>
      <TextLine>Access the best brands locally and globally to source products</TextLine>
      <TextLine>End-to-end logistics and support</TextLine>
      <TextLine>Upfront information on everything you need: timelines, costs, MOQs, and more</TextLine>
    </TextContentContainer>
  );
};

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Sellers = () => {
  return (
    <SectionContainer>
      <ImageContent />
      <TextContent />
    </SectionContainer>
  );
};

export default Sellers;
