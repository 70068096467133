import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Decimal: { input: any; output: any; }
};

export type BaseProduct = {
  __typename?: 'BaseProduct';
  companyEmail: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  /** List of customizations for the base product */
  customizations: Array<BaseProductCustomization>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  leadTime: Scalars['String']['output'];
  minimumOrderQuantity: Scalars['String']['output'];
  productImage?: Maybe<Scalars['String']['output']>;
  productName: Scalars['String']['output'];
  /** List of quantity tier pricings for the base product */
  quantityTierPricings: Array<BaseProductQuantityTierPricing>;
  saleUnit: Scalars['String']['output'];
  sample: Scalars['String']['output'];
  shipping: Scalars['String']['output'];
};

export type BaseProductCustomization = {
  __typename?: 'BaseProductCustomization';
  baseProductId?: Maybe<Scalars['Int']['output']>;
  customizationType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  /** List of possible values for the customization */
  values?: Maybe<Array<Scalars['String']['output']>>;
};

export type BaseProductOrderInput = {
  baseProductId: Scalars['ID']['input'];
  companyName: Scalars['String']['input'];
  companyWebsite: Scalars['String']['input'];
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  quantity: Scalars['String']['input'];
  shippingAddress: Scalars['String']['input'];
};

export type BaseProductQuantityTierPricing = {
  __typename?: 'BaseProductQuantityTierPricing';
  id: Scalars['ID']['output'];
  maxRangeQuantity?: Maybe<Scalars['Decimal']['output']>;
  minRangeQuantity?: Maybe<Scalars['Decimal']['output']>;
  priceAmount: Scalars['Decimal']['output'];
  priceCurrency: Scalars['String']['output'];
};

/** Autogenerated return type of CreateBaseProductOrder. */
export type CreateBaseProductOrderPayload = {
  __typename?: 'CreateBaseProductOrderPayload';
  errors: Array<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createBaseProductOrder?: Maybe<CreateBaseProductOrderPayload>;
};


export type MutationCreateBaseProductOrderArgs = {
  input: BaseProductOrderInput;
};

export type Query = {
  __typename?: 'Query';
  baseProduct: BaseProduct;
  baseProducts: Array<BaseProduct>;
};


export type QueryBaseProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBaseProductsArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBaseProductOrderMutationVariables = Exact<{
  fullName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  companyWebsite: Scalars['String']['input'];
  shippingAddress: Scalars['String']['input'];
  quantity: Scalars['String']['input'];
  baseProductId: Scalars['ID']['input'];
}>;


export type CreateBaseProductOrderMutation = { __typename?: 'Mutation', createBaseProductOrder?: { __typename?: 'CreateBaseProductOrderPayload', errors: Array<string> } | null };

export type GetBaseProductQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetBaseProductQuery = { __typename?: 'Query', baseProduct: { __typename?: 'BaseProduct', id: string, companyName: string, productName: string, minimumOrderQuantity: string, saleUnit: string, description: string, productImage?: string | null, sample: string, shipping: string, leadTime: string, quantityTierPricings: Array<{ __typename?: 'BaseProductQuantityTierPricing', id: string, minRangeQuantity?: any | null, maxRangeQuantity?: any | null, priceAmount: any, priceCurrency: string }>, customizations: Array<{ __typename?: 'BaseProductCustomization', id: string, customizationType: string, label: string, values?: Array<string> | null }> } };

export type GetBaseProductsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetBaseProductsQuery = { __typename?: 'Query', baseProducts: Array<{ __typename?: 'BaseProduct', id: string, companyName: string, productName: string, minimumOrderQuantity: string, saleUnit: string, description: string, productImage?: string | null, sample: string, shipping: string, leadTime: string, quantityTierPricings: Array<{ __typename?: 'BaseProductQuantityTierPricing', id: string, minRangeQuantity?: any | null, maxRangeQuantity?: any | null, priceAmount: any, priceCurrency: string }> }> };


export const CreateBaseProductOrderDocument = gql`
    mutation CreateBaseProductOrder($fullName: String!, $email: String!, $phone: String!, $companyName: String!, $companyWebsite: String!, $shippingAddress: String!, $quantity: String!, $baseProductId: ID!) {
  createBaseProductOrder(
    input: {fullName: $fullName, email: $email, phone: $phone, companyName: $companyName, companyWebsite: $companyWebsite, shippingAddress: $shippingAddress, quantity: $quantity, baseProductId: $baseProductId}
  ) {
    errors
  }
}
    `;
export type CreateBaseProductOrderMutationFn = Apollo.MutationFunction<CreateBaseProductOrderMutation, CreateBaseProductOrderMutationVariables>;

/**
 * __useCreateBaseProductOrderMutation__
 *
 * To run a mutation, you first call `useCreateBaseProductOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBaseProductOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBaseProductOrderMutation, { data, loading, error }] = useCreateBaseProductOrderMutation({
 *   variables: {
 *      fullName: // value for 'fullName'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      companyName: // value for 'companyName'
 *      companyWebsite: // value for 'companyWebsite'
 *      shippingAddress: // value for 'shippingAddress'
 *      quantity: // value for 'quantity'
 *      baseProductId: // value for 'baseProductId'
 *   },
 * });
 */
export function useCreateBaseProductOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateBaseProductOrderMutation, CreateBaseProductOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBaseProductOrderMutation, CreateBaseProductOrderMutationVariables>(CreateBaseProductOrderDocument, options);
      }
export type CreateBaseProductOrderMutationHookResult = ReturnType<typeof useCreateBaseProductOrderMutation>;
export type CreateBaseProductOrderMutationResult = Apollo.MutationResult<CreateBaseProductOrderMutation>;
export type CreateBaseProductOrderMutationOptions = Apollo.BaseMutationOptions<CreateBaseProductOrderMutation, CreateBaseProductOrderMutationVariables>;
export const GetBaseProductDocument = gql`
    query GetBaseProduct($id: ID!) {
  baseProduct(id: $id) {
    id
    companyName
    productName
    minimumOrderQuantity
    saleUnit
    description
    productImage
    sample
    shipping
    leadTime
    quantityTierPricings {
      id
      minRangeQuantity
      maxRangeQuantity
      priceAmount
      priceCurrency
    }
    customizations {
      id
      customizationType
      label
      values
    }
  }
}
    `;

/**
 * __useGetBaseProductQuery__
 *
 * To run a query within a React component, call `useGetBaseProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBaseProductQuery(baseOptions: Apollo.QueryHookOptions<GetBaseProductQuery, GetBaseProductQueryVariables> & ({ variables: GetBaseProductQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaseProductQuery, GetBaseProductQueryVariables>(GetBaseProductDocument, options);
      }
export function useGetBaseProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaseProductQuery, GetBaseProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaseProductQuery, GetBaseProductQueryVariables>(GetBaseProductDocument, options);
        }
export function useGetBaseProductSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBaseProductQuery, GetBaseProductQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBaseProductQuery, GetBaseProductQueryVariables>(GetBaseProductDocument, options);
        }
export type GetBaseProductQueryHookResult = ReturnType<typeof useGetBaseProductQuery>;
export type GetBaseProductLazyQueryHookResult = ReturnType<typeof useGetBaseProductLazyQuery>;
export type GetBaseProductSuspenseQueryHookResult = ReturnType<typeof useGetBaseProductSuspenseQuery>;
export type GetBaseProductQueryResult = Apollo.QueryResult<GetBaseProductQuery, GetBaseProductQueryVariables>;
export const GetBaseProductsDocument = gql`
    query GetBaseProducts($search: String) {
  baseProducts(search: $search) {
    id
    companyName
    productName
    minimumOrderQuantity
    saleUnit
    description
    productImage
    sample
    shipping
    leadTime
    quantityTierPricings {
      id
      minRangeQuantity
      maxRangeQuantity
      priceAmount
      priceCurrency
    }
  }
}
    `;

/**
 * __useGetBaseProductsQuery__
 *
 * To run a query within a React component, call `useGetBaseProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseProductsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetBaseProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetBaseProductsQuery, GetBaseProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaseProductsQuery, GetBaseProductsQueryVariables>(GetBaseProductsDocument, options);
      }
export function useGetBaseProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaseProductsQuery, GetBaseProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaseProductsQuery, GetBaseProductsQueryVariables>(GetBaseProductsDocument, options);
        }
export function useGetBaseProductsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBaseProductsQuery, GetBaseProductsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBaseProductsQuery, GetBaseProductsQueryVariables>(GetBaseProductsDocument, options);
        }
export type GetBaseProductsQueryHookResult = ReturnType<typeof useGetBaseProductsQuery>;
export type GetBaseProductsLazyQueryHookResult = ReturnType<typeof useGetBaseProductsLazyQuery>;
export type GetBaseProductsSuspenseQueryHookResult = ReturnType<typeof useGetBaseProductsSuspenseQuery>;
export type GetBaseProductsQueryResult = Apollo.QueryResult<GetBaseProductsQuery, GetBaseProductsQueryVariables>;